

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}



select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

/* Bright Green: #77be44  */
/* Dark Green: #4E5B30 */

/* 
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  color: #4E5B30
}

.navbar-default .navbar-brand {
  color: #4E5B30;
}

.navbar-default .navbar-brand:focus, .navbar-default .navbar-brand:hover {
  color: #738646;
}

.navbar-default .navbar-nav>li>a, .navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus {
  color: #4E5B30;
}

.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
  color: #738646;
}

 .navbar-default .navbar-nav>.active>a:hover {
  color: #738646;
} */

/* */

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #444;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  color: #000000
}

.navbar-default .navbar-brand {
  color: #B30505;
}

.navbar-default .navbar-brand:focus, .navbar-default .navbar-brand:hover {
  color: #C23737;
}

.navbar-default .navbar-nav>li>a, .navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus {
  color: #B30505;
}

.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
  color: #C23737;
}

 .navbar-default .navbar-nav>.active>a:hover {
  color: #C23737;
}

/* Color Picker: http://colllor.com/4E5B30 */

/* color: #5c6b38; */

.container {
  width: unset;
}